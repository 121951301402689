import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import { LocalStorageKey } from '../../../shared/constants/local-storage-key.const';

@Injectable({
  providedIn: 'root',
})
export class AdiModulesServiceService {
  private oktaAuth = inject(OKTA_AUTH);
  moduleDetails$ = new BehaviorSubject<any | undefined>(undefined);
  readNotification$ = new Subject<any>();

  updateNotificationList$ = new Subject<any>();

  constructor(private http: HttpClient) {}
  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.oktaAuth.getAccessToken()}`,
      'X-Adi-Source': 'adi-platform',
      'X-Adi-Trace-Id': localStorage.getItem(LocalStorageKey.TRACE_ID)!,
      'X-Adi-Identity': this.oktaAuth.getIdToken() ?? '',
    });
  }

  setUpdateNotificationList() {
    this.updateNotificationList$.next(true);
  }

  getUpdateNotificationList() {
    return this.updateNotificationList$.asObservable();
  }

  setReadNotification(data: any) {
    this.readNotification$.next(data);
  }

  getReadNotification() {
    return this.readNotification$.asObservable();
  }
  getModuleDetails(): Observable<any> {
    const language = localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE);
    const url =
      environment.API_BE_ENDPOINT + '/auth/modules?langCode=' + language;
    return this.http.get(url);
  }
  callModuleDetails(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getModuleDetails().subscribe({
        next: (res: any) => {
          this.setModuledata(res);
          resolve(true);
        },
        error: (err) => {
          reject();
        },
      });
    });
  }
  setModuledata(data: any) {
    if (
      JSON.stringify(this.moduleDetails$.getValue()) !== JSON.stringify(data)
    ) {
      this.moduleDetails$.next(data);
    }
  }

  getModulesdata() {
    return this.moduleDetails$.asObservable();
  }

  enrollModule(appCode: string, practiceOrg: string) {
    let headers = this.getHeaders();
    const selectedAccountId = localStorage.getItem(
      LocalStorageKey.SELECTED_ACCOUNT_ID
    );
    const url = environment.API_BE_ENDPOINT + '/auth/module/enroll';
    const body = {
      sapId: selectedAccountId,
      appCode: appCode,
      practiceOrgId: practiceOrg,
    };
    return this.http.post(url, body, { headers, responseType: 'text' });
  }
}
